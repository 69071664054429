<template>
  <div class="culture">
    <div class="banner">
      <img src="@/assets/culture/banner.jpg">
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated animate__fadeInUp">
        <p class="cn">企业文化</p>
        <p class="en">COMPANY CULTURE</p>
      </div>
    </div>
    <div class="box1">
      <p class="p1 animate__animated animate__fadeInUp">“haidapu”源于“hadoop”（大数据技术）</p>
      <p class="p2 animate__animated animate__fadeInUp"><span>海</span>——群英荟萃、海纳百川</p>
      <p class="p2 animate__animated animate__fadeInUp"><span>多</span>——厚积薄发、专业创新</p>
      <p class="p2 animate__animated animate__fadeInUp"><span>普</span>——致富思源、服务社会</p>
    </div>
    <div class="box2">
      <div class="item1">
        <span class="shad"></span>
        <img src="@/assets/culture/bg_1.jpg">
        <p class="tit">企业愿景</p>
        <p class="txt">智能引领时代，智慧改变生活</p>
      </div>
      <div class="item2">
        <span class="shad"></span>
        <img src="@/assets/culture/bg_2.jpg">
        <p class="tit">企业使命</p>
        <p class="txt">让工作更智慧，让环境更和谐</p>
      </div>
      <div class="item3">
        <span class="shad"></span>
        <img src="@/assets/culture/bg_3.jpg">
        <p class="tit">企业文化</p>
        <p class="txt">专业成就品质，服务创造价值</p>
      </div>
      <div class="item4">
        <span class="shad"></span>
        <img src="@/assets/culture/bg_4.jpg">
        <p class="tit">企业价值观</p>
        <p class="txt">服务客户、精准求实、诚信共享、创业创新</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Culture',
  metaInfo: {
    title: '智慧城管|垃圾治理|垃圾分类|智慧环卫|智慧公厕|智能AI分析-海多普-企业文化',
    meta: [
      {
        name: 'keyWords',
        content: '海多普,企业文化,智慧城管,垃圾治理,垃圾分类,智慧环卫,智慧公厕,智能AI分析'
      },
      {
        name: 'description',
        content: '海多普，城市管理信息服务专家'
      }
    ]
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.culture {
  width: 100%;
  // background-color: #F7F7F7;
  overflow: hidden;
  .banner {
    width: 100%;
    overflow: hidden;
    margin-bottom: 60px;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  .animate__animated {
    opacity: 0;
  }
  .title {
    height: 60px;
    margin-bottom: 50px;
    .title-wrapper {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .cn {
        font-size: 30px;
      }
      .en {
        font-size: 16px;
        color: #99acbc;
        font-weight: 600;
      }
    }
  }
  .box1 {
    background-color: #F9F9F9;
    padding-top: 76px;
    padding-bottom: 85px;
    .p1 {
      font-size: 38px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 75px;
      color: #194898;
    }
    .p2 {
      span {
        font-weight: bold;
        color: #333333;
      }
      color: #666666;
      font-size: 28px;
      text-align: center;
      line-height: 48px;
    }
  }
  .box2 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 130px;
    div {
      width: 50%;
      // height: 460px;
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      position: relative;
      overflow: hidden;
      // padding-top: 136px;
      .shad {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
        background-color: rgba($color: #155EDB, $alpha: 0.7);
        transform: translateY(-100%);
        transition: all .2s;
      }
      &:hover {
        .shad {
          transform: translateY(0);
        }
      }
      img {
        width: 100%;
        display: block;
      }
      p {
        position: absolute;
        // top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        color: #FFFFFF;
        text-align: center;
        &.tit {
          top: 29.5%;
          font-size: 40px;
          height: 60px;
          margin-bottom: 66px;
          &::after {
            content: ' ';
            width: 56px;
            height: 7px;
            position: absolute;
            background-color: #FFFFFF;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        &.txt {
          font-size: 30px;
          width: 100%;
          top: 57.2%;
        }
      }
      // &.item1 {
      //   background-image: url('~@/assets/culture/bg_1.jpg');
      // }
      // &.item2 {
      //   background-image: url('~@/assets/culture/bg_2.jpg');
      // }
      // &.item3 {
      //   background-image: url('~@/assets/culture/bg_3.jpg');
      // }
      // &.item4 {
      //   background-image: url('~@/assets/culture/bg_4.jpg');
      // }
    }
  }
}
</style>